<template>
    <div class="pageMain">
      <div v-if="infoShow">
      <search-head :model="searchForm" @search="search" @reset="reset"  :isExport="true">
        <el-form :inline="true" :model="searchForm">
        <el-form-item label="租赁公司名称">
          <el-input v-model="searchForm.busiName" size="small" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      </search-head>
      <custom-tables :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          height="55vh"
          :total="total"
          title="租赁单位监控统计信息">
        <!-- <template slot="btns">
          <el-button type="primary" size="small" @click="addFrom">新建</el-button>
        </template> -->
        <el-table-column type="selection" align="center" width="55">
      </el-table-column>
        <el-table-column
            prop="busiName"
            label="租赁单位名称"
            align="center"
          >
        </el-table-column>
        <el-table-column
            prop="busiLinker"
            align="center"
            label="联系人"
          >
        </el-table-column>
        <el-table-column
            prop="busiTel"
            align="center"
            label="联系电话"
          >
        </el-table-column>
        <el-table-column
            prop="alarmCount"
            align="center"
            label="报警（次）"
          >
        </el-table-column>
        <el-table-column
            prop="illegalCount"
            align="center"
            label="违章（次）"
          >
        </el-table-column>
       
          <!-- <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <span
                  @click="detailItem(scope.row)"
                  class="detail"
                >查看</span>
              </template>
            </el-table-column> -->
      </custom-tables>
     
    </div>
    <!-- <violation v-if="editShow"></violation> -->
    </div>
  </template>
  
  <script>
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import CustomDialog from "../../../components/common/CustomDialog";
// import violation from '../../../components/dataCenter/violation.vue'
import data from "../../../api/modules/data";
  export default {
    name: "index",
    components: {CustomDialog, CustomTables, SearchHead,},
    data() {
      return {
        tableData: [
        
        ],
        searchForm: {
        currPage: 1,
      pageSize: 10,
      },
        total:0,
        showDialog: false,
        headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow:false,
      infoShow:true
      }
    },
    mounted() {
      this.getList()
    },
    methods:{
      handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
      getList(){
        data.getDataBusinessListByPage().then(res=>{
          this.total = res.data.total;
      this.tableData = res.data.records;
        })
      },
      search(){
        console.log('searchForm', this.searchForm)
        this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList()
      },
      reset(){
        this.searchForm = {
      currPage: 1,
      pageSize: 10,
     
    };
    this.getList()
      },
      addFrom(){
        this.showDialog = true
      },
      close(){
        this.showDialog = false
      },
      submit(){
        this.showDialog = false
      },
      detailItem(item){
        //详情
        this.$router.push({
       path: `/view/monitor/illegalDel`,
       query: {
        item: item.busiId,
      
    }
     });
        
    },
    handleSelectionChange(val){
        console.log(val);
        
    }
  
    }
  }
  </script>
  
  <style scoped lang="less">

  </style>